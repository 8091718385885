<script lang="ts">
    import * as Dialog from '$lib/components/ui/dialog';
    import { Button } from '$lib/components/ui/button';
    import { Textarea } from '$lib/components/ui/textarea';
    import * as Select from '$lib/components/ui/select';
    import toast from 'svelte-french-toast';

    export let show: boolean = false;
    export let user: any;
    export let hasContacts: boolean = true;
    export let app: { baseUrl: string } = { baseUrl: '' };

    let message: string = '';
    let receiverId: string = user?.id || '';
    let isSubmitting: boolean = false;

    const csrfTokenMeta = document.querySelector('meta[name="csrf-token"]');
    const csrfToken = csrfTokenMeta ? csrfTokenMeta.getAttribute('content') || '' : '';

    async function handleSubmit(): Promise<void> {
        if (!message.trim()) {
            toast.error('Please enter a message');
            return;
        }

        try {
            isSubmitting = true;

            const data = new URLSearchParams();
            data.append('receiverIDs[]', receiverId);
            data.append('message', message);

            const response = await fetch(`${app.baseUrl}/my/messenger/sendMessage`, {
                method: 'POST',
                headers: {
                    'X-CSRF-TOKEN': csrfToken,
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: data,
                credentials: 'same-origin',
            });

            const result = await response.json();

            if (!response.ok) {
                throw new Error(result.message || 'Failed to send message');
            }

            message = '';
            show = false;

            window.location.assign(`${app.baseUrl}/my/messenger`);
        } catch (error: any) {
            console.error('Send message error:', error);
            toast.error(error.message || 'Failed to send message');
        } finally {
            isSubmitting = false;
        }
    }

    $: if (!show) {
        message = '';
        isSubmitting = false;
    }
</script>

<Dialog.Root bind:open="{show}">
    <Dialog.Content>
        <Dialog.Header>
            <Dialog.Title>
                {user ? `Send a new message to ${user.name}` : 'Send a new message'}
            </Dialog.Title>
        </Dialog.Header>

        {#if hasContacts}
            <form on:submit|preventDefault="{handleSubmit}">
                {#if !user}
                    <Select.Root portal="{null}">
                        <Select.Trigger class="mb-4 w-full">
                            <Select.Value placeholder="To..." />
                        </Select.Trigger>
                        <Select.Content>
                            <Select.Group>
                                <!-- Contacts list here -->
                            </Select.Group>
                        </Select.Content>
                        <Select.Input bind:value="{receiverId}" name="receiverId" />
                    </Select.Root>
                {:else}
                    <input type="hidden" name="receiverId" value="{receiverId}" />
                {/if}

                <Textarea bind:value="{message}" placeholder="Your message" class="mb-4" disabled="{isSubmitting}" />

                <div class="flex justify-end gap-2">
                    <Button variant="outline" type="button" on:click="{() => (show = false)}" disabled="{isSubmitting}">
                        Close
                    </Button>
                    <Button type="submit" disabled="{isSubmitting}">
                        {isSubmitting ? 'Sending...' : 'Send'}
                    </Button>
                </div>
            </form>
        {:else}
            <p>Before sending a new message, please subscribe to a creator a follow a free profile.</p>
            <div class="mt-4 flex justify-end">
                <Button variant="outline" on:click="{() => (show = false)}">Close</Button>
            </div>
        {/if}
    </Dialog.Content>
</Dialog.Root>
